import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Flex, Button } from 'rebass/styled-components'

import { getBeerFilters } from '../data/beer-filters'

const BeerFilter = ({ intl, activeFilter, onFilterChange }) => {
  const beerFilters = getBeerFilters(intl.locale)

  return (
    <Flex flexWrap="wrap" justifyContent="center">
      {beerFilters && beerFilters.map((filter) => {
        return (
          <Button
            key={filter.key}
            type="button"
            variant="outlinePrimary"
            className={activeFilter === filter.key && 'active'}
            sx={{
              px: 3, py: 1, mr: 2, mb: 2, fontSize: 4, lineHeight: 'body',
            }}
            onClick={() => onFilterChange(filter.key)}
          >
            {filter.label}
          </Button>
        )
      })}
    </Flex>
  )
}

BeerFilter.propTypes = {
  intl: PropTypes.object.isRequired,
  activeFilter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

export default injectIntl(BeerFilter)
