const sortBeers = (beers) => {
  const order = {
    all: 0,
    // CS
    'aktualne-v-prodeji': 1,
    'stala-nabidka': 2,
    'sezonni-specialy': 3,
    kooperace: 4,
    'pivni-serie': 5,
    'vyrocni-specialy': 6,
    // EN
    'current-offer': 1,
    'permanent-offer': 2,
    'seasonal-special-beers': 3,
    'cooperation-en': 4,
    'beer-series': 5,
    'annual-special-beers': 6,
  }

  const compareFunction = (a, b) => {
    const aObj = order[a.type ? a.type : a.key]
    const bObj = order[b.type ? b.type : b.key]

    let comparison = 0
    if (aObj > bObj) {
      comparison = 1
    } else if (aObj < bObj) {
      comparison = -1
    }
    return comparison
  }

  return beers.sort(compareFunction)
}

export default sortBeers
