/* eslint-disable import/prefer-default-export */
import { useStaticQuery, graphql } from 'gatsby'
import getLocaleMessages from '../utils/getLocaleMessages'

import sortBeers from '../utils/sortBeers'

const beerIsInCategory = (beer, filteredCategory) => {
  if (!beer.beer_cat) {
    return false
  }
  
  const beerCategories = beer.beer_cat
  
  if (Array.isArray(beerCategories)) {
    return beerCategories.some(category => category.slug === filteredCategory.slug)
  }
}

export const getBeerFilters = (locale) => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressWpBeerCat {
        edges {
          node {
            slug
            name
          }
        }
      }
      allWordpressWpBeerType {
        edges {
          node {
            polylang_current_lang
            beer_cat {
              slug
            }
          }
        }
      }
    }
  `)

  const filters = data.allWordpressWpBeerCat.edges
  const beersTypes = data.allWordpressWpBeerType.edges

  const localeBeerTypes = beersTypes.filter((beerType) => {
    return beerType.node.polylang_current_lang.substring(0, 2) === locale
  })

  const availableFilters = [
    {
      key: 'all',
      label: getLocaleMessages(locale).beers.all,
    },
  ]

  filters.forEach((filter) => {
    const hasBeers = localeBeerTypes.filter(
      (beerType) => beerIsInCategory(beerType.node, filter.node),
    )

    if (hasBeers.length > 0) {
      availableFilters.push({
        key: filter.node.slug,
        label: filter.node.name,
      })
    }
  })

  const sortedFilters = sortBeers(availableFilters)

  return sortedFilters
}
