import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import BeerFilter from '../components/beer-filter'
import BeerList from '../components/beer-list'
import NextStep from '../components/next-step'
import normalizeBeers from '../utils/normalizeBeers'
import getTranslations from '../utils/getTranslations'
import sortBeers from '../utils/sortBeers'

const BeersPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    page, allWordpressWpBeerType: wpBeers, heroBackgroundImage, nextStepBackground,
  } = data
  const {
    title, content, acf, polylang_translations: translations,
  } = page
  const {
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
  } = acf
  const [activeFilter, setActiveFilter] = useState('all')

  const normalizedBeers = normalizeBeers(wpBeers.edges)
  const beers = sortBeers(normalizedBeers)

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        html={content}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 20%"
      />

      <SectionWithObjects>
        <Box mb={4} mx="auto">
          <BeerFilter
            activeFilter={activeFilter}
            onFilterChange={(key) => setActiveFilter(key)}
          />
        </Box>

        <Container>
          <BeerList beers={beers} filter={activeFilter} />
        </Container>
      </SectionWithObjects>

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

BeersPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BeersPage

export const pageQuery = graphql`
  fragment EtiquettePNGBackground on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  fragment EtiquettePNGTop on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  fragment EtiquettePNGBottom on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  query BeersPageQuery($key: String!, $locale: String) {
    page: wordpressPage(slug: {eq: $key}) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
      }
    }
    allWordpressWpBeerType(sort: {order: ASC, fields: menu_order}, filter: {polylang_current_lang: {eq: $locale}}) {
      edges {
        node {
          polylang_current_lang
          title
          slug
          beer_cat {
            slug
          }
          acf {
            kombinace_s_jidlem
            idealni_teplota
            barva
            nazev_piva
            obsah_alkoholu
            slad
            text_oceneni
            etiquette {
              background_png {
                ...EtiquettePNGBackground
              }
              top_png {
                ...EtiquettePNGTop
              }
              bottom_png {
                ...EtiquettePNGBottom
              }
            }
          }
        }
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "michal-degustuje.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "pivo-bar.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
