import cs from '../locale/cs'
import en from '../locale/en'

const localeMap = {
  cs,
  en,
}

const getLocaleMessages = (locale) => {
  return localeMap[locale] || cs
}

export default getLocaleMessages
