import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Heading } from 'rebass/styled-components'

import { Link } from 'gatsby'
import TiltLabel from './tilt-label'

const BeerList = ({ beers, filter }) => {
  // Filter beers by set filter, return true if filter is set to "all" or type of beer === filter
  const filteredBeers = beers.filter(
    (beer) =>
      filter === 'all' ||
      beer.types.some((beerType) => {
        return beerType === filter
      }),
  )

  const wrapperWidth =
    filteredBeers.length === 4 ? ['100%', '48%', '25%'] : ['100%', '48%', '33%']

  return (
    <Flex
      sx={{
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: ['flex-start', 'space-around', 'center'],
        alignItems: ['center', 'flex-start'],
      }}
    >
      {filteredBeers &&
        filteredBeers.map((beer) => {
          return (
            <Box
              key={beer.key}
              title={beer.name}
              sx={{
                width: wrapperWidth,
                mb: [3, 2, 3],
              }}
            >
              <Link
                to={beer.link}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                {!beer.etiquette.background && (
                  <Heading as="h2" sx={{ color: 'white' }}>
                    {beer.name}
                  </Heading>
                )}

                {beer.etiquette && (
                  <TiltLabel
                    background={beer.etiquette.background}
                    top={beer.etiquette.top}
                    bottom={beer.etiquette.bottom}
                  />
                )}
              </Link>
            </Box>
          )
        })}
    </Flex>
  )
}

BeerList.propTypes = {
  beers: PropTypes.array.isRequired,
  filter: PropTypes.string,
}

BeerList.defaultProps = {
  filter: 'all',
}

export default BeerList
